import React, { memo } from 'react';
import './App.scss';
import HeroSection from './components/HeroSection';
import Marketplaces from './components/Marketplaces';
import HowItWorks from './components/HowItWorks';
import Value from './components/Value';
import Features from './components/Features';
import Testimonials from './components/Testimonials';
import Pricing from './components/Pricing';
import Helmet from 'react-helmet';

import { isServerRender } from '../../utils';

function App({ isMobile, onOpenModal }) {
  
  return (
    <>
      <Helmet>
        <title>Lysted | The easiest way to sell your tickets!</title>
        <link rel="canonical" href="https://lysted.com/" />
      </Helmet>
      <HeroSection onSignup={onOpenModal} isMobile={isMobile} />
      <Marketplaces onSignup={onOpenModal} isMobile={isMobile} />
      <HowItWorks onSignup={onOpenModal} isMobile={isMobile} />
      <Value onSignup={onOpenModal} isMobile={isMobile} />
      <Features onSignup={onOpenModal} isMobile={isMobile} />
      {!isServerRender && (
        <Testimonials onSignup={onOpenModal} isMobile={isMobile} />
      )}
      <Pricing onSignup={onOpenModal} isMobile={isMobile} />
    </>
  );
}

export default memo(App);