import React from 'react';
import Select from 'react-select';

const TeamSelect = ({ teams, selectedTeam, onChange, className }) => {
    const teamOptions = teams.map((team) => ({
        value: team.team.slug,
        label: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={team.team.logos[0]?.href} alt={team.team.displayName} style={{ width: '20px', height: '20px', borderRadius: '50%', marginRight: '8px' }} />
                {team.team.displayName}
            </div>
        ),
    }));

    teamOptions.push({ value: 'other', label: 'Other' });

    return (
        <Select
            options={teamOptions}
            className={className}
            value={teamOptions.find((option) => option.value === selectedTeam)}
            onChange={(selectedOption) => onChange(selectedOption.value)}
            placeholder={'Select a team...'}
            
        />
    );
};

export default TeamSelect;
