import React from 'react';
import './FormSectionHeader.scss';
import cx from 'classnames';

function FormSectionHeader({
    title,
    rightNode,
    className,
    isMobile
}) {

    return (
        <div className={cx('section-divider', {
            [className]: !!className,
            'mobile': isMobile
        })}>
            <div className="section-divider-line" />
            <div className="section-name">{title}</div>
            {!!rightNode && (
                <div className="right">{rightNode}</div>
            )}
        </div>
    );
}

export default FormSectionHeader;