import React, { useState } from 'react';
import './Navigation.scss';
import { Link } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';
import LogIn from '../../../LogIn';
import cx from 'classnames';
import ReactModal from 'react-modal';
import { useCookiebot } from '../../../../../utils/CookiebotContext';

ReactModal.setAppElement('#root');

function Navigation({ isMobile, onSignup, isIntercomOpen, onCloseMenu, location }) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { isIntercomAllowed } = useCookiebot();
    
    // @note we have to use the window Intercom ref to open/close since loading useIntercom can't be conditional, and the user might not have consented to Intercom cookies

    // Function to check user cookie consent status
    const handleClickSupport = () => {
        if (isIntercomAllowed) {
            if (isIntercomOpen) {
                window.Intercom('hide');
            } else {
                window.Intercom('show');
            }
            if (isMobile) {
                onCloseMenu();
            }
        } else {
            setModalIsOpen(true);
        }
    };

    const shouldUseScrollLink = ['/terms', '/privacy', '/signup', '/login'].includes(location?.pathname);

    const navigationElements = [
        {
            label: 'How it works',
            target: 'how-it-works',
            to: './',
            type: 'scroll',
            spy: true,
            smooth: true,
            offset: -80,
            duration: 500,
            className: 'nav-element',
            activeClass: 'active',
        },
        {
            label: 'Features',
            target: 'features',
            to: './',
            type: 'scroll',
            spy: true,
            smooth: true,
            offset: -80,
            duration: 500,
            className: 'nav-element',
            activeClass: 'active',
        },
        {
            label: 'Pricing',
            target: 'pricing',
            to: './',
            type: 'scroll',
            spy: true,
            smooth: true,
            offset: -80,
            duration: 500,
            className: 'nav-element',
            activeClass: 'active',
        },
        {
            label: 'FAQ',
            target: 'https://help.lysted.com/en/articles/8283282-frequently-asked-questions',
            blank: true,
            type: 'link',
            className: 'nav-element',
        },
        {
            label: 'Blog',
            target: 'https://resources.lysted.com',
            blank: true,
            type: 'link',
            className: 'nav-element',
        },
        {
            label: 'Support',
            target: '.',
            type: 'func',
            className: 'nav-element',
            onClick: handleClickSupport,
            isActive: isIntercomOpen,
        },
    ];

    const handleSignup = () => {
        onCloseMenu();
        onSignup();
    }

    return (
        <>
        <nav className={cx('navigation', {
            'mobile': isMobile
        })}>
            {navigationElements.map((element, index) => {
                if ((element.type === 'scroll') && !shouldUseScrollLink) {
                    // ScollLink type
                    return (
                        <ScrollLink
                            key={`nav-${index}`}
                            className={element.className}
                            activeClass={element.activeClass}
                            to={element.target}
                            spy={element.spy}
                            smooth={element.smooth}
                            offset={element.offset}
                            duration={element.duration}
                            onClick={onCloseMenu}
                        >
                            {element.label}
                        </ScrollLink>
                    )
                } else if ((element.type === 'link') || (shouldUseScrollLink && (element.type === 'scroll'))) {
                    // Link type
                    return (
                        <Link
                            key={`nav-${index}`}
                            className={cx(element.className, {
                                'active': element.isActive
                            })}
                            to={element.type === 'link' ? element.target : `${element.to}#${element.target}`}
                            target={element.blank ? '_blank' : null}
                            rel={element.blank ? 'noopener noreferrer' : null}
                            onClick={onCloseMenu}
                        >
                            {element.label}
                        </Link>
                    )
                }

                // Function type
                return (
                    <div
                        key={`nav-${index}`}
                        className={cx(element.className, {
                            'active': element.isActive
                        })}
                        onClick={element.onClick}
                    >
                        {element.label}
                    </div>
                )
            })}
            {isMobile && (
                <>
                    <LogIn
                        className="nav-button"
                        onClick={onCloseMenu}
                    />
                    <div
                        onClick={handleSignup}
                        className="nav-button"
                    >
                        Sign Up
                    </div>
                </>
            )}
        </nav>
        <ReactModal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel="Enable Cookies"
            className="cookies-modal"
            overlayClassName="cookies-modal-overlay"
        >
            <div className='cookies-modal-header'>
                <div>Support Requires Cookies</div>
            </div>
            <div className='cookies-modal-body'>
                <p>To use our support chat feature, cookies need to be enabled in your browser.</p>
                <p>Please open the cookie settings below and update your preferences.</p>
                <p>
                    If you choose not to enable cookies, you can still reach our support team via email at <a href="mailto:lystedsupport@automatiq.com">lystedsupport@automatiq.com</a>.
                </p>
            </div>
            <div className='cookies-modal-footer'>
                <button className='btn' onClick={() => setModalIsOpen(false)}>Close</button>
                <button className='btn cookies-modal-button-primary' onClick={() => { setModalIsOpen(false); window.Cookiebot?.show(); }}>Open Cookie Settings</button>
            </div>
        </ReactModal>
    </>
    );
}

export default Navigation;
