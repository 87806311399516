import React, { useState, useEffect } from 'react';
import './SportSelector.scss';
import { Controller } from 'react-hook-form';
import FormSectionHeader from '../FormSectionHeader';
import cx from 'classnames';
import TeamSelect from './TeamSelect';
import axios from 'axios';

const SportSelector = ({ control, errors, onLeagueSelected, onTeamSelected, progressionCallback, isMobile }) => {
    const [selectedLeague, setSelectedLeague] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [data, setData] = useState(null);

    useEffect(() => {
        axios.get(`${process.env.PUBLIC_URL}/assets/data/SportsTeams.json`)
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.error("Error fetching JSON:", error);
            });
    }, []);

    const handleLeagueSelect = (league) => {
        if (progressionCallback) {
            progressionCallback();
        }
        setSelectedLeague(league);
        if (onLeagueSelected) {
            onLeagueSelected(league);
        }
    };

    const handleTeamSelect = (team) => {
        setSelectedTeam(team);
        if (onTeamSelected) {
            onTeamSelected(team);
        }
    };

    return (
        <>
            <FormSectionHeader
                className="section-divider-custom"
                title={!selectedLeague ? 'Season tickets league?' : 'Season tickets team?'}
            />
            <div className={cx('sport-selector-container', {
                'mobile': isMobile
            })}>
                {(!selectedLeague && data) && (
                    <Controller
                        name={'league'}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <div className="leagues">
                                {data.sports.map((sport) =>
                                    sport.leagues.map((league) => (
                                        <div
                                            key={league.abbreviation}
                                            className={cx('league-item', {
                                                'selected': selectedLeague === league
                                            })}
                                            onClick={() => {
                                                field.onChange({
                                                    sport: sport.slug,
                                                    league: league.slug
                                                });
                                                handleLeagueSelect({
                                                    sport: sport.slug,
                                                    league: league.slug
                                                })
                                            }}
                                        >
                                            <img src={league.logos[0]?.href} alt={league.abbreviation} />
                                            <span>{league.abbreviation}</span>
                                        </div>
                                    ))
                                )}
                                <div
                                    className={cx('league-item', {
                                        'selected': selectedLeague === 'other'
                                    })}
                                    onClick={() => {
                                        field.onChange({
                                            sport: 'other',
                                            league: 'other'
                                        });
                                        handleLeagueSelect({
                                            sport: 'other',
                                            league: 'other'
                                        });
                                    }}
                                >
                                    <span>Other</span>
                                </div>
                            </div>
                        )}
                    />
                )}
                {!!selectedLeague && (
                    <Controller
                        name={'team'}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => (
                            <div className="teams">
                                <TeamSelect
                                    teams={data.sports.find(s => s.slug === selectedLeague?.sport && s.leagues.find(l => l.slug === selectedLeague?.league))?.leagues[0].teams}
                                    selectedTeam={selectedTeam}
                                    className={'select'}
                                    onChange={(selectedTeam) => {
                                        field.onChange(selectedTeam);
                                        handleTeamSelect(selectedTeam);
                                    }}
                                />
                            </div>
                        )}
                    />
                )}
                {(errors['league'] || errors['team']) && (
                    <span className="error-message">Please select an option</span>
                )}
            </div>
        </>
    );
};

export default SportSelector;
