import React from 'react';
import './Pricing.scss';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';

function Pricing({ onSignup, isMobile }) {
    return (
        <section id="pricing" className={cx('pricing', {
            'mobile': isMobile
        })}>
            <div className="pricing-content">
                <div className="headline">Pricing</div>
                <div className="pricing-description-container">
                    <div className="left">
                        <section className="pricing-section">
                            <div className="pricing-section-headline">Simple, transparent pricing</div>
                            <div className="pricing-section-description">
                                As partners with the major ticket exchanges, we have earned discounted sell fees which we pass along to our users. On average, you'll pay around 11% of your list price - which is inclusive of both our fee, and the selling marketplace fee. Each sale can vary slightly depending which marketplace your tickets sell on.
                                <br />
                                <br />
                                Selling lots of tickets? Take advantage of our volume discount tiers and lower this fee by up to 40%!
                                <div className="pricing-section-extras">
                                    <Link
                                        className="btn arrow"
                                        to="https://help.lysted.com/en/articles/6932561-rate-sheet-and-fee-structure"
                                        target={'_blank'}
                                        rel={'noopener noreferrer'}
                                    >
                                        {'Rate sheet & fee structure'}
                                        <FontAwesomeIcon size="xs" icon={faChevronRight} className="chevron-icon" />
                                    </Link>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="right">
                        <div className="pricing-card-container">
                            <div className="pricing-card">
                                <div className="pricing-meta-top">
                                    <span className="pricing-meta-large">Free to list</span>
                                    <span className="pricing-meta-medium">
                                        {"It's free to"}
                                        <div className="link" onClick={onSignup}>
                                            {"sign up"}
                                        </div>
                                        {", too!"}
                                    </span>
                                </div>
                                <div className="pricing-meta-bottom">
                                    <span className="pricing-meta-medium">Service fee</span>
                                    <span className="pricing-meta-large">
                                        {"11% "}
                                        <FontAwesomeIcon icon={faAsterisk} size="2xs" className="meta-asterik" />
                                    </span>
                                    <span className="pricing-meta-small">
                                        {"*Average service fee. Service fee depends on several factors such as marketplace and pricing preference."}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Pricing;
