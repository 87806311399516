import React, { memo } from 'react';
import './ImageSection.scss';
import cx from 'classnames';
import HlsVideoPlayer from '../../../common/components/HlsVideoPlayer/HlsVideoPlayer';

function ImageSection({ isMobile }) {
    return (
        <div className={cx('image-section', {
            'mobile': isMobile
        })} aria-hidden="true">
            <div className={cx('dashboard-graphic', {
                'mobile': isMobile
            })} aria-hidden="true">
                <HlsVideoPlayer src={`${process.env.PUBLIC_URL}/_resources/hero-video/master.m3u8`} />
            </div>
        </div>
    );
}

export default memo(ImageSection);
