
const plaidLightningCountries = [
  "AR", // Argentina
  "AU", // Australia
  "AT", // Austria
  "BE", // Belgium
  "BR", // Brazil
  "CA", // Canada
  "CL", // Chile
  "CN", // China
  "CO", // Colombia
  "CZ", // Czech Republic
  "DK", // Denmark
  "FI", // Finland
  "FR", // France
  "DE", // Germany
  "GI", // Gibraltar
  "HK", // Hong Kong
  "IN", // India
  "IE", // Ireland
  "IT", // Italy
  "JP", // Japan
  "KE", // Kenya
  "LU", // Luxembourg
  "MY", // Malaysia
  "MX", // Mexico
  "NL", // Netherlands
  "NZ", // New Zealand
  "NG", // Nigeria
  "NO", // Norway
  "PH", // Philippines
  "PL", // Poland
  "PT", // Portugal
  "SG", // Singapore
  "SK", // Slovakia
  "ZA", // South Africa
  "ES", // Spain
  "SE", // Sweden
  "CH", // Switzerland
  "TR", // Turkey
  "GB", // United Kingdom
  "US", // United States
];

export default plaidLightningCountries;
