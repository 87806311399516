import React, { useState, useEffect } from 'react';
import ReactConfetti from 'react-confetti';
import cx from 'classnames';
import './Confetti.scss';

const Confetti = () => {
    const [showConfetti, setShowConfetti] = useState(true);
    const [hideComponent, setHideComponent] = useState(false);

    const colors = ['#9801cc', '#13b2f9', '#ff333d', '#90e0ff', '#ffcb57']

    useEffect(() => {
        let hideTimeout = null;
        const confettiTimeout = setTimeout(() => {
            setShowConfetti(false);
            hideTimeout = setTimeout(() => setHideComponent(true), 1000);
        }, 2000);

        return () => {
            clearTimeout(confettiTimeout);
            if (hideTimeout) {
                clearTimeout(hideTimeout);
            }
        };
    }, []);

    return !hideComponent ? (
        <div className={cx('confetti-container', {
            'hidden': !showConfetti
        })}>
            <ReactConfetti
                width={window.innerWidth}
                height={window.innerHeight}
                colors={colors}
                gravity={0.3}
            />
        </div>
    ) : null;
};

export default Confetti;
