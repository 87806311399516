import React, { useCallback, memo } from "react";
import './LogIn.scss';
import auth0 from 'auth0-js';
import { getAuthRedirectURI } from '../../../utils';
import cx from 'classnames'

const LogIn = ({ onClick, className, location, isMobile, asRoute, shouldLogout }) => {

    const memoizedGetAuthRedirectURI = useCallback(() => {
        return getAuthRedirectURI(location);
    }, [location]);

    const webauth = new auth0.WebAuth({
        audience: 'https://lysted.com/api',
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
        redirectUri: memoizedGetAuthRedirectURI(),
        responseType: 'code',
        leeway: 60,
        scope: 'openid profile email'
    });

    if (asRoute) {
        if (shouldLogout) {
            webauth.logout({
                clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
                returnTo: process.env.REACT_APP_DOMAIN
            })
        } else {
            webauth.authorize();
        }
        
        return (
            <div className={cx('login', {
                'mobile': isMobile
            })}>
                <div className={cx('login-wrapper', {
                    'mobile': isMobile
                })}>
                    <h1>{shouldLogout ? 'Logging out, please wait...' : 'Log in with Automatiq ID...'}</h1>
                </div>
            </div>
        );
    }
    
    return (
        <div
            className={className}
            onClick={e => {
                e.preventDefault();

                if (onClick) {
                    onClick();
                }

                webauth.authorize();
            }}
        >
            {'Log In'}
        </div>
    );
};

export default memo(LogIn);