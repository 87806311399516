import React, { useEffect, useState, useRef, memo } from 'react';
import './ImageMarquee.scss';
import cx from 'classnames'

const ImageMarquee = memo(({ images, isMobile }) => {
    const [isHovered, setIsHovered] = useState(false);
    const marqueeRef = useRef(null);

    useEffect(() => {
        const handleMouseEnter = () => {
            setIsHovered(true);
        };

        const handleMouseLeave = () => {
            setIsHovered(false);
        };

        const marqueeElement = marqueeRef.current;

        marqueeElement.addEventListener('mouseenter', handleMouseEnter);
        marqueeElement.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            marqueeElement.removeEventListener('mouseenter', handleMouseEnter);
            marqueeElement.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, []);

    return (
        <div
            className={cx('wrapper', {
                'paused': isHovered,
                'mobile': isMobile
            })}
            ref={marqueeRef}
        >
            <div className="marquee">
                <div className="marquee__group">
                    {images.map((image, index) => (
                        <img
                            key={`partner-v-${index}`}
                            src={image.src}
                            width={image.width}
                            height={image.height}
                            alt={`Partner ${index + 1}`}
                            loading="lazy"
                        />
                    ))}
                </div>

                <div aria-hidden="true" className="marquee__group">
                    {images.map((image, index) => (
                        <img
                            key={`partner-${index}`}
                            src={image.src}
                            width={image.width}
                            height={image.height}
                            alt={`Partner - ${image.name}`}
                            loading="lazy"
                        />
                    ))}
                </div>
            </div>
        </div>
    );
});

export default ImageMarquee;
