import React, { useEffect, useState, memo } from 'react';
import './RotatingWord.scss';
import cx from 'classnames';

const WORDS = ['fastest', 'smart', 'easiest'];

const RotatingWord = ({ isMobile }) => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => setIndex((index) => index + 1), 4000);
        
        return () => clearInterval(intervalId);
    }, []);

    return (
        <h1 className={cx('rotating-container', {
            'mobile': isMobile
        })}>
            <span className="no-rotate">{'The '}</span>
            <span className="rotating-word">
                {WORDS[index % WORDS.length]}
            </span><br />
            <span className="no-rotate">{' way to sell your tickets'}</span>
        </h1>
    );
};

export default memo(RotatingWord);
