import React from 'react';
import './Terms.scss';
import cx from 'classnames';
import Helmet from 'react-helmet';

function Terms({ isMobile }) {
	return (
		<>
			<Helmet>
				<title>Lysted | Terms of Service</title>
				<link rel="canonical" href="https://lysted.com/terms" />
			</Helmet>
			<div className={cx('terms', {
				'mobile': isMobile
			})}>
				<div className={cx('terms-wrapper', {
					'mobile': isMobile
				})}>
						<h1>Lysted Terms of Service</h1>
						<p>Our Terms of Service in now located <a href="https://help.lysted.com/en/articles/8809352-terms-of-service">here</a>.</p>
				</div>
			</div>
		</>
	);
}

export default Terms;
