import React, { memo } from 'react';
import './Marketplaces.scss';
import ImageMarquee from '../../../common/components/ImageMarquee';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';

function Marketplaces({ isMobile }) {

    const images = [
        {
            src: 'https://upload.wikimedia.org/wikipedia/commons/3/32/Stubhub.svg',
            width: 120,
            height: 'auto',
            name: 'StubHub',
        },
        {
            src: 'https://upload.wikimedia.org/wikipedia/commons/7/7d/TicketMaster_wordmark.svg',
            width: 150,
            height: 'auto',
        },
        {
            src: 'https://upload.wikimedia.org/wikipedia/commons/0/07/Vivid_Seats_logo.svg',
            width: 150,
            height: 'auto',
            name: 'ViviSeats',
        },
        {
            src: 'https://upload.wikimedia.org/wikipedia/commons/f/fd/Seatgeek_logo.svg',
            width: 90,
            height: 'auto',
            name: 'SeatGeek',
        },
        {
            src: 'https://upload.wikimedia.org/wikipedia/commons/2/28/Gametime_logo.svg',
            width: 150,
            height: 'auto',
            name: 'Gametime',
        },
        {
            src: 'https://upload.wikimedia.org/wikipedia/commons/1/1f/TickPick_logo.svg',
            width: 150,
            height: 'auto',
            name: 'TickPick',
        },
        {
            src: 'https://dtr2k13nvgx2o.cloudfront.net/assets/images/global/logo/tn-logo-gray-240x23.png',
            width: 180,
            height: 'auto',
            name: 'TicketNetwork',
        },
        {
            src: 'https://www.tevo.com/wp-content/uploads/cropped-13.png',
            width: 120,
            height: 'auto',
            name: 'TEVO',
        },
    ];

    return (
        <section className={cx('marketplaces', {
            'mobile': isMobile
        })}>
            <div className="marketplaces-content">
                <div className="marketplaces-announce-wrapper">
                    <div className="marketplaces-announce">
                        <span className="large">Our partners</span>
                        <span className="small">
                            If it's Lysted here, it's listed there
                        </span>
                    </div>
                    <FontAwesomeIcon size="xs" icon={faChevronRight} className="chevron-icon" />
                </div>
                <div className="marketplaces-partners">
                    <ImageMarquee images={images} isMobile={isMobile} />
                </div>
            </div>
        </section>
    );
}

export default memo(Marketplaces);
