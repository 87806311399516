import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import './HlsVideoPlayer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeSlash } from '@fortawesome/pro-light-svg-icons'

const HlsVideoPlayer = ({ src, shouldLoop = true }) => {
  const videoRef = useRef(null);
  const [controls, setControls] = useState(false);
  const [muted, setMuted] = useState(true);

  useEffect(() => {
    let hls;

    if (videoRef && videoRef.current) {
      const video = videoRef.current;

      if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = src;
        
      } else if (Hls.isSupported()) {
        hls = new Hls();
        hls.loadSource(src);
        hls.attachMedia(video);
      }
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [src]);

  const handleControls = () => {
    if (!controls) {
      setControls(true)
    }
  };

  const handleVolumeChange = () => {
    if (videoRef.current) {
      setMuted(videoRef.current.muted);
    }
  };

  const handleUnmute = () => {
    if (videoRef.current) {
      videoRef.current.muted = false;
    }
  }

  return (
    <>
      {muted && (
        <FontAwesomeIcon size="sm" icon={faVolumeSlash} className="play-button" onClick={handleUnmute} />
      )}
      <video 
        ref={videoRef} 
        muted
        playsInline
        autoPlay
        loop={shouldLoop}
        style={{ width: '100%' }}
        preload={'none'}
        controls={controls}
        onMouseEnter={handleControls}
        onTouchStart={handleControls}
        onVolumeChange={handleVolumeChange}
      />
    </>
    
  );
};

export default HlsVideoPlayer;
