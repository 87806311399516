import React, { useEffect, useState, memo } from 'react';
import './Header.scss';
import { slide as Menu } from 'react-burger-menu';
import { Link } from "react-router-dom";
import Navigation from './components/Navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import LogIn from '../LogIn';

function Header({ isMobile, onSignup, isIntercomOpen, location }) {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window?.scrollY > 100) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window?.addEventListener('scroll', handleScroll);

        return () => {
            window?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleToggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const handleCloseMenu = () => {
        setIsMenuOpen(false);
    }

    const handleMenuStateChange = ({ isOpen }) => {
        setIsMenuOpen(isOpen);
    }

    return (
        <header className={cx('sticky-header', {
            'scrolled': isScrolled,
            'mobile': isMobile,
            'non-home': ['/terms', '/privacy', '/signup', '/login'].includes(location?.pathname)
        })}>
            <div className="container">
                <Link
                    className="logo"
                    to={{
                        pathname: './',
                        search: location?.search
                    }}
                >
                    <img src="/assets/login/brand/lysted-white.svg" alt="Lysted" aria-hidden="true"></img>
                </Link>
                {isMobile ? (
                    <Menu
                        right
                        isOpen={isMenuOpen}
                        onStateChange={handleMenuStateChange}
                        width={190}
                        styles={{
                            bmMenuWrap: {
                                zIndex: 1006,
                                top: 0,
                                padding: 26,
                                backgroundColor: 'rgba(255,255,255,.95)'
                            },
                            bmOverlay: {
                                zIndex: 1006,
                                left: 0,
                                top: 0
                            },
                            bmBurgerButton: {
                                display: 'none'
                            }
                        }}
                        customBurgerIcon={null}
                    >
                        <Navigation
                            isMobile={isMobile}
                            onSignup={onSignup}
                            onCloseMenu={handleCloseMenu}
                            isIntercomOpen={isIntercomOpen}
                            location={location}
                        />
                    </Menu>
                ) : (
                        <Navigation
                            isMobile={isMobile}
                            onSignup={onSignup}
                            isIntercomOpen={isIntercomOpen}
                            onCloseMenu={null}
                            location={location}
                        />
                )}
                <div className="nav-buttons">
                    {!isMobile ? (
                        <>
                            <LogIn className="btn transparent white" location={location} />
                            <div onClick={onSignup} className="btn">Sign Up</div>
                        </>
                    ) : (
                        <FontAwesomeIcon
                            className="menu-icon"
                            icon={faBars}
                            onClick={handleToggleMenu} />
                    ) }
                </div>
            </div>
        </header>
    );
}

export default memo(Header);
