import React from 'react';
import { Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './BlockOptions.scss';
import cx from 'classnames'



function BlockOptions({ name, options, control, errors, onOptionSelected, className, isMobile }) {
    return (
        <div className={cx('block-options', {
            'mobile': isMobile
        })}>
            <Controller
                name={name}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                    <div className={cx('block-options-container', {
                        [className]: !!className,
                        'mobile': isMobile
                    })}>
                        {options.map(option => (
                            <div
                                key={option.value}
                                className={cx('option', {
                                    [option.className]: !!option.className,
                                    'selected': field.value === option.value,
                                    'mobile': isMobile
                                })}
                                onClick={() => {
                                    field.onChange(option.value);
                                    if (onOptionSelected) {
                                        onOptionSelected(option.value);
                                    }
                                }}
                            >
                                {!!option.icon && <FontAwesomeIcon icon={option.icon} className="option-icon" />}
                                <span className={cx('option-label', {
                                    'no-icon': !option.icon
                                })}>
                                    {option.label}
                                    {!!option.contextLabel && (
                                        <span className='context'>{option.contextLabel}</span>
                                    )}
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            />
            {errors[name] && (
                <span className="error-message">Please select an option</span>
            )}
        </div>
    );
}

export default BlockOptions;
