import React, { useEffect, useState, useRef } from 'react';
import './Testimonials.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';

const testimonialData = [
    {
        quote: "All in one, literally. The most reliable and useful resource for selling tickets ive used, cant go wrong at all and their team above all is very responsive and helpful if you happen to have any confusion with anything. Recommend 100%!",
        author: "Cheva G",
        rating: 5,
    },
    {
        quote: "I've used Lysted multiple times to sell my tickets and they have the most user friendly and supportive staff out there! 10/10 Recommend using Lysted!",
        author: "Evan S",
        rating: 5,
    },
    {
        quote: "I've been using Lysted for several months now.  Their customer service is great and fees are transparent.  I am happy with their service.  5 stars!",
        author: "Bryan S",
        rating: 5,
    },
    {
        quote: "Great service, great UI, I've sold thousands worth of tickets with Lysted and there's been no issues so far.",
        author: "Isaiah N",
        rating: 5,
    },
    {
        quote: "I have used Lysted for about a month. I did interview a couple of the other companies that provide similar services. I went with Lysted because I felt their customer service was superior. It is a good feeling to be right. They have blown all expectations out of the water. I highly recommend listed for anyone that could be of use of fulfillment services.",
        author: "Jason C",
        rating: 5,
    },
    {
        quote: "I hate selling tickets but needed to since I couldn't make the concert I bought tickets to. After not being to sell on Stubhub for a while, I learned about Lysted and promptly sold them on a website I couldn't list on. The user interface is very easy to use and setting the price is super simple. I highly recommend using Lysted if you're having trouble selling tickets (or Ticketmaster is not allowing resale).",
        author: "Jonathan H",
        rating: 5,
    },
    {
        quote: "I highly recommend Lysted to anybody who is selling tickets whether as a livelihood or as a side gig. Drew really hit this project out of the park! Can't say enough good things about them.",
        author: "Mark S",
        rating: 5,
    },
    {
        quote: "Lysted has been a game changer. Totally changed my business. I went from working hard to hardly working, but making more money doing so. Can't recommend higher!",
        author: "Brian S",
        rating: 5,
    },
    {
        quote: "Lysted provides a great platform that allows me to list my tickets on all marketplaces rather than have to go individually do so. Their support is responsive and friendly as well.",
        author: "Nate Y",
        rating: 5,
    },
];

function Testimonials({ isMobile }) {
    const [currentSlide, setCurrentSlide] = useState(0);
    const intervalRef = useRef(null);

    useEffect(() => {
        const intervalDuration = window.innerWidth < 768 ? 5000 : 10000;

        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % testimonialData.length);
        }, intervalDuration);

        intervalRef.current = interval;

        return () => clearInterval(intervalRef.current);
    }, []);

    const handleDotClick = (index) => {
        clearInterval(intervalRef.current);
        setCurrentSlide(index);

        const intervalDuration = window.innerWidth < 768 ? 5000 : 10000;
        intervalRef.current = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % testimonialData.length);
        }, intervalDuration);
    };

    return (
        <section className={cx('testimonials', {
            'mobile': isMobile
        })}>
            <div className="testimonials-background-mask">
                <div className="testimonials-background" />
            </div>
            <div className="testimonials-content">
                <div className="headline">Reviews</div>
                <div className="testimonials-description-container">
                    <div className="left">
                        <section className="testimonials-section">
                            <div className="testimonials-section-headline">See what our customers are saying</div>
                            <div className="testimonials-section-description">
                                At Lysted, we take great pride in delivering solutions that truly resonate with our clients. But don't just take our word for it — let our clients speak for themselves. Here's what they have to say about their experience working with us.
                            </div>
                        </section>
                    </div>
                    <div className={cx('right', {
                        'mobile': isMobile
                    })}>
                        <div className="carousel-container">
                            <div className="carousel-navigation">
                                {testimonialData.map((_, index) => (
                                    <div
                                        key={index}
                                        className={`carousel-dot ${index === currentSlide ? 'active' : ''
                                            }`}
                                        onClick={() => handleDotClick(index)}
                                    />
                                ))}
                            </div>
                            <div className="testimonials-animation-container">
                                {testimonialData.map((testimonial, index) => (
                                    <div
                                        key={index}
                                        className={`testimonial-card ${index === currentSlide ? 'active' : ''}`}
                                        onMouseEnter={() => {
                                            if (window.innerWidth >= 768) {
                                                clearInterval(intervalRef.current);
                                            }
                                        }}
                                        onMouseLeave={() => {
                                            if (window.innerWidth >= 768) {
                                                const intervalDuration = window.innerWidth < 768 ? 5000 : 10000;
                                                intervalRef.current = setInterval(() => {
                                                    setCurrentSlide((prevSlide) => (prevSlide + 1) % testimonialData.length);
                                                }, intervalDuration);
                                            }
                                        }}
                                    >
                                        <div className="reviewer">
                                            <div className="name-container">
                                                <img src="https://services.google.com/fh/files/misc/google_g_icon_download.png" alt="Google Rating Icon" />
                                                <span className="name">{testimonial.author}</span>
                                            </div>
                                            <div className="stars">
                                                {Array.from({ length: 5 }).map((_, starIndex) => (
                                                    <FontAwesomeIcon
                                                        size="xs"
                                                        icon={faStar}
                                                        key={starIndex}
                                                        className={`star ${starIndex < testimonial.rating ? 'filled' : ''}`}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                        <blockquote>{testimonial.quote}</blockquote>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonials;
