import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { Controller } from 'react-hook-form'
import { Link } from 'react-router-dom';
import './DOBSelector.scss';
import cx from 'classnames'
import Tooltip from '../Tooltip'

import FormSectionHeader from '../FormSectionHeader'

// Construct an array of years between 120 and 18 years ago
const currentYear = new Date().getFullYear()
const years = Array.from({ length: 103 }, (_, i) => ({
  value: currentYear - 18 - i,
  label: `${currentYear - 18 - i}`,
}))

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const months = monthNames.map((month, i) => ({
  value: i + 1,
  label: month,
}))

const DOBSelector = ({
  isMobile,
  className,
  control,
  setValue,
  getValues,
  errors,
}) => {
  const dobDay = getValues('dobDay');

  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear()
  )
  const [selectedMonth, setSelectedMonth] = useState(0)
  const [days, setDays] = useState([])

  const [selectorStates, setSelectorStates] = useState({
    month: {
      focused: false,
      filled: false
    },
    day: {
      focused: false,
      filled: false
    },
    year: {
      focused: false,
      filled: false
    },
});

  useEffect(() => {
    const updateDays = (numDays) => {
      const dayOptions = Array.from({ length: numDays }, (_, i) => ({
        value: i + 1,
        label: `${i + 1}`,
      }))
      setDays(dayOptions)

      if (!dayOptions.find(d => d.value === dobDay) && dobDay !== null) {
        // Directly clear the 'dobDay' using React Hook Form's `setValue`
        setValue('dobDay', null, { shouldValidate: false });
      }
    }

    // Leap years are divisible by 4 AND not every year divisible by 100 is a leap year unless its divisible by 400
    const isLeapYear = selectedYear % 4 === 0 &&
      (selectedYear % 100 !== 0 || selectedYear % 400 === 0);

    if (selectedMonth === 2) {
      if (isLeapYear) {
        // February in a leap year
        updateDays(29)
      } else {
        // February in a non-leap year
        updateDays(28)
      }
    } else if ([4, 6, 9, 11].includes(selectedMonth)) {
      // April, June, September, November have 30 days
      updateDays(30)
    } else if (selectedMonth !== null && selectedMonth !== undefined) {
      // Months with 31 days (excluding the case when month hasn't been selected yet)
      updateDays(31)
    }
  }, [selectedMonth, dobDay, selectedYear, setValue, getValues])

  function getDobCopy() {
    return (
      <div className="dob-tooltip">
        <p>
          <strong>Security and Fraud Prevention: </strong> 
          Your date of birth is a crucial piece of information used in the process of verifying your identity.            </p>
        <p>
          <strong>Age Verification: </strong>
          Our <Link to={'https://help.lysted.com/en/articles/8809352-terms-of-service'} target={'_blank'} rel={'noopener noreferrer'}>terms of service</Link> stipulate that you must be at least 18 years old and capable of entering into legally binding contracts to use our platform
        </p>
      </div>
    )
  }

  return (
    <>
      <FormSectionHeader
        className="section-divider"
        title="Date of Birth"
        rightNode={
          <Tooltip
            id="sign-up-form-dob-explainer"
            label={'Why do we collect this?'}
            linkClassName=""
            tooltipClassName=""
          >
            {getDobCopy()}
          </Tooltip>
        }
        isMobile={isMobile}
      />

      <div
        className='valid-dob-req'
      >
        Entering an incorrect date of birth will affect account approval
      </div>

      <div className="form-group">
        <div className="form-field">
          <Controller
            name="dobMonth"
            control={control}
            rules={{ required: 'Month is required' }}
            render={({ field }) => (
              <Select
                {...field}
                options={months}
                className={cx(
                  'form-field',
                  'select',
                  'mid',
                  {
                    error: errors.dobMonth,
                  },
                  className
                )}
                value={months.find(m => m.value === field.value)}
                placeholder="Month"
                onChange={(selectedOption) => {
                  setSelectedMonth(selectedOption?.value ?? 1);
                  field.onChange(selectedOption?.value);
                  setSelectorStates(prevState => ({
                    ...prevState,
                    month: {
                      ...prevState.month,
                      filled: !!selectedOption
                    }
                  }));
                }}
                onFocus={() => {
                  setSelectorStates(prevState => ({
                    ...prevState,
                    month: {
                      ...prevState.month,
                      focused: true,
                    }
                  }));
                }}
                onBlur={() => {
                  setSelectorStates(prevState => ({
                    ...prevState,
                    month: {
                      ...prevState.month,
                      focused: false,
                    }
                  }));
                }}
              />
            )}
          />
          <label
            htmlFor="dobMonth"
            className={cx({
              'filled': selectorStates.month.filled,
              'focused': selectorStates.month.focused && selectorStates.month.filled
            })}
          >
            {"Month"}
          </label>
          {errors.dobMonth && <span className="error">{errors.dobMonth.message}</span>}
        </div>

        <div className="form-field">
          <Controller
            name="dobDay"
            control={control}
            rules={{ required: 'Day is required' }}
            render={({ field }) => (
              <Select
                {...field}
                options={days}
                className={cx(
                  'form-field',
                  'select',
                  'mid',
                  {
                    error: errors.dobDay,
                  },
                  className
                )}
                value={days.find(d => d.value === field.value) || null}
                placeholder="Day"
                onChange={(selectedOption) => {
                  field.onChange(selectedOption?.value)
                  setSelectorStates(prevState => ({
                    ...prevState,
                    day: {
                      ...prevState.day,
                      filled: !!selectedOption
                    }
                  }));
                }}
                onFocus={() => {
                  setSelectorStates(prevState => ({
                    ...prevState,
                    day: {
                      ...prevState.day,
                      focused: true,
                    }
                  }));
                }}
                onBlur={() => {
                  setSelectorStates(prevState => ({
                    ...prevState,
                    day: {
                      ...prevState.day,
                      focused: false,
                    }
                  }));
                }}
              />
            )}
          />
          <label
            htmlFor="dobDay"
            className={cx({
              'filled': selectorStates.day.filled,
              'focused': selectorStates.day.focused && selectorStates.day.filled
            })}
          >
            {"Day"}
          </label>
          {errors.dobDay && <span className="error">{errors.dobDay.message}</span>}
        </div>

        <div className="form-field">
          <Controller
            name="dobYear"
            control={control}
            rules={{ required: 'Year is required' }}
            render={({ field }) => (
              <Select
                {...field}
                options={years}
                className={cx(
                  'form-field',
                  'select',
                  'mid',
                  {
                    error: errors.dobYear,
                  },
                  className
                )}
                value={years.find(y => y.value === field.value)}
                placeholder="Year"
                onChange={(selectedOption) => {
                  setSelectedYear(
                    selectedOption?.value ?? new Date().getFullYear()
                  )
                  field.onChange(selectedOption?.value)
                  setSelectorStates(prevState => ({
                    ...prevState,
                    year: {
                      ...prevState.year,
                      filled: !!selectedOption
                    }
                  }));
                }}
                onFocus={() => {
                  setSelectorStates(prevState => ({
                    ...prevState,
                    year: {
                      ...prevState.year,
                      focused: true,
                    }
                  }));
                }}
                onBlur={() => {
                  setSelectorStates(prevState => ({
                    ...prevState,
                    year: {
                      ...prevState.year,
                      focused: false,
                    }
                  }));
                }}
              />
            )}
          />
          <label
            htmlFor="dobYear"
            className={cx({
              'filled': selectorStates.year.filled,
              'focused': selectorStates.year.focused && selectorStates.year.filled
            })}
          >
            {"Year"}
          </label>
          {errors.dobYear && <span className="error">{errors.dobYear.message}</span>}
        </div>
      </div>
    </>
  )
}

export default DOBSelector
