import React, { memo } from 'react';
import './HeroSection.scss';
import TitleSection from './TitleSection';
import ImageSection from './ImageSection';
import cx from 'classnames';

function HeroSection({ onSignup, isMobile }) {

    return (
        <section className={cx('hero', {
            'mobile': isMobile
        })}>
            <div className={cx('hero-gradient', {
                'mobile': isMobile
            })} />
            <div className={cx('hero-content', {
                'mobile': isMobile
            })}>
                <TitleSection onSignup={onSignup} isMobile={isMobile} />
                <ImageSection isMobile={isMobile} />
            </div>
        </section>
    );
}

export default memo(HeroSection);
