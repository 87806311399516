import React, { useEffect } from 'react';
import './Privacy.scss';
import cx from 'classnames';
import Helmet from 'react-helmet';

function Privacy({ isMobile }) {

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.replace('https://automatiq.com/privacy');
    }, 0);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <Helmet>
          <title>Lysted | Privacy Policy</title>
          <link rel="canonical" href="https://lysted.com/privacy" />
      </Helmet>
      <div className={cx('privacy', {
        'mobile': isMobile
      })}>
        <div className={cx('privacy-wrapper', {
          'mobile': isMobile
        })}>
          <span>Redirecting to Automatiq Privacy Policy...</span>
        </div>
      </div>
    </>
  );
}

export default Privacy;
