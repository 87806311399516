import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './StepNavigation.scss';
import cx from 'classnames'

function StepNavigation({ steps, currentStep, onClose, isMobile }) {
    const visibleSteps = steps.filter(s => s.visible);
    const totalVisibleSteps = visibleSteps.length;

    // Find the index of the first visible step and add one to get the actual step progress
    let currentStepActual = steps.findIndex((step, _) => step.visible && (_ <= (currentStep - 1))) + 1;
    
    const stepData = steps[currentStep - 1];
    if (stepData?.visible && stepData?.id > 1) {
        currentStepActual = 2;
    }
    const progressWidth = ((currentStepActual - 1) / (totalVisibleSteps - 1)) * 100;

    return (
        <div className={cx('step-navigation', {
            'mobile': isMobile
        })}>
            <div className="step-line">
                <div className="step-progress" style={{ width: `${progressWidth}%` }} />
            </div>
            {visibleSteps.map((step, index) => {
                const isActive = index === (currentStepActual - 1);
                return (
                    <div
                        key={step.id}
                        className={cx('step', {
                            'completed': step.completed,
                            'active': isActive
                        })}
                    >
                        <div className={cx('step-circle', {
                            'completed': step.completed && isActive
                        })}>
                            <FontAwesomeIcon icon={step.icon} />
                        </div>
                        {!isMobile && (
                            <div className="step-name">{step.name}</div>
                        )}
                    </div>
                );
            })}
        </div>
    );
}

export default StepNavigation;
