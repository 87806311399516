import {
    faBuilding,
    faUserPlus,
    faTicketAlt,
    faHandshake,
    faUserFriends,
    faRectangleAd,
    faEnvelope
} from '@fortawesome/free-solid-svg-icons';
import {
    faFacebookF,
    faInstagram,
    faTiktok,
    faXTwitter,
    faDiscord,
    faSlack,
    faSearchengin
} from '@fortawesome/free-brands-svg-icons';
import {
    faGlobePointer
} from '@fortawesome/pro-light-svg-icons'

export const isServerRender = typeof window === 'undefined';

export const useUTMParams = (location) => {
    const queryParams = new URLSearchParams(location?.search);

    const utmSource = queryParams.get('utm_source');
    const utmMedium = queryParams.get('utm_medium');
    const utmCampaign = queryParams.get('utm_campaign');
    const isSignupRequested = queryParams.has('signup');
    const hasUTMParameters = utmSource && utmMedium && utmCampaign;

    return {
        utmSource,
        utmMedium,
        utmCampaign,
        hasUTMParameters,
        isSignupRequested
    };
};

export const useAmbassadorParams = (location) => {
    const queryParams = new URLSearchParams(location?.search);

    const mbsySource = queryParams.get('mbsy_source');
    const mbsyCampaign = queryParams.get('campaignid');
    const mbsyexp = queryParams.get('mbsy_exp');
    const shortcode = queryParams.get('mbsy');

    return {
        mbsySource,
        mbsyCampaign,
        mbsyexp,
        shortcode
    };
};

export const getAuthRedirectURI = (location) => {
    const queryParams = new URLSearchParams(location?.search);

    const utmSource = queryParams.get('utm_source');
    const utmMedium = queryParams.get('utm_medium');
    const utmCampaign = queryParams.get('utm_campaign');

    // Build the updated redirect_uri with existing UTM parameters
    const redirectUri = new URL(process.env.REACT_APP_AUTH0_LOGIN_REDIRECT);

    if (utmSource) {
        redirectUri.searchParams.set('utm_source', utmSource);
    }

    if (utmMedium) {
        redirectUri.searchParams.set('utm_medium', utmMedium);
    }

    if (utmCampaign) {
        redirectUri.searchParams.set('utm_campaign', utmCampaign);
    }

    return redirectUri.toString();
};

export const sellerOptions = [
    {
        value: 'large',
        label: 'Established Ticket Broker',
        contextLabel: 'Over $100K /yr in sales',
        icon: faBuilding,
        className: 'established',
    },
    {
        value: 'broker',
        label: 'New Ticket Broker',
        contextLabel: 'Under $100K /yr in sales',
        icon: faUserPlus,
        className: 'new',
    },
    {
        value: 'season',
        label: 'Season Ticket Holder',
        icon: faTicketAlt,
        className: 'season',
    },
    {
        value: 'small',
        label: 'One Time / Occasional',
        icon: faHandshake,
        className: 'one-time',
    },
];

export const volumeOptions = [
    {
        value: '100K_500K',
        label: '$100K - $500K',
        icon: null,
        className: 'volume-option',
    },
    {
        value: '500K_1M',
        label: '$500K - $1M',
        icon: null,
        className: 'volume-option',
    },
    {
        value: '1M_2M',
        label: '$1M - $2M',
        icon: null,
        className: 'volume-option',
    },
    {
        value: '2M_5M',
        label: '$2M - $5M',
        icon: null,
        className: 'volume-option',
    },
    {
        value: 'above_5M',
        label: 'Greater than $5M',
        icon: null,
        className: 'volume-option',
    },
];

export const referralOptions = [
    {
        value: 'facebook',
        label: 'Facebook',
        icon: faFacebookF,
        className: 'referral-option',
    },
    {
        value: 'instagram',
        label: 'Instagram',
        icon: faInstagram,
        className: 'referral-option',
    },
    {
        value: 'tiktok',
        label: 'TikTok',
        icon: faTiktok,
        className: 'referral-option',
    },
    {
        value: 'x-twitter',
        label: 'X / Twitter',
        icon: faXTwitter,
        className: 'referral-option',
    },
    {
        value: 'discord',
        label: 'Discord',
        icon: faDiscord,
        className: 'referral-option',
    },
    {
        value: 'slack',
        label: 'Slack',
        icon: faSlack,
        className: 'referral-option',
    },
    {
        value: 'advertisement',
        label: 'Advertisement',
        icon: faRectangleAd,
        className: 'referral-option',
    },
    {
        value: 'email-referral',
        label: 'Email',
        icon: faEnvelope,
        className: 'referral-option',
    },
    {
        value: 'game-concert-show',
        label: 'Game / Concert / Show',
        icon: faTicketAlt,
        className: 'referral-option',
    },
    {
        value: 'friend-referral',
        label: 'From a Friend',
        icon: faUserFriends,
        className: 'referral-option',
    },
    {
        value: 'direct-visit',
        label: 'Direct Visit',
        icon: faGlobePointer,
        className: 'referral-option',
    },
    {
        value: 'search-engine',
        label: 'Search Engine',
        icon: faSearchengin,
        className: 'referral-option',
    },
];