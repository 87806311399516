import React from 'react';
import './Value.scss';
import cx from 'classnames';

function Value({ isMobile }) {
    return (
        <section className={cx('value', {
            'mobile': isMobile
        })}>
            <div className="value-background-mask">
                <div className="value-background" />
            </div>
            <div className="value-content">
                <h3 className="headline">Value</h3>
                <div className="value-description-container">
                    <div className="left">
                        <section className="value-section">
                            <div className="value-section-headline">Sell with the industry-leading consignment platform</div>
                            <div className="value-section-description">
You don't need to be a professional to use Lysted; we sell tickets for all kinds of people! Everyone from fans who can no longer attend events to season seat holders and professional ticket brokers. You can trust Lysted to resell tickets honestly, competitively, and quickly. Learn more about how to sell concert tickets with Lysted today.                            </div>
                        </section>
                    </div>
                    {!isMobile && (
                        <div className="right">
                        <div className="value-animation-container">
                            <img src="/assets/login/img/concert-crowd.jpg" alt="Concert" aria-hidden="true" />
                        </div>
                    </div>
                    )}
                </div>
                <div className="metrics-container">
                    <div className="metric">
                        <span className="metric-value">7M+</span>
                        <span className="metric-description">We've sold over 7 million tickets for our customers.</span>
                    </div>
                    <div className="metric">
                        <span className="metric-value">50K+</span>
                        <span className="metric-description">Over 50,000 people trust Lysted to sell their seats.</span>
                    </div>
                    <div className="metric">
                        <span className="metric-value">$1.2B+</span>
                        <span className="metric-description">Lysted has distributed over $1.2 billion via direct deposit.</span>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Value;
