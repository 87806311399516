import React from 'react';
import './Tooltip.scss';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'

function FormSectionHeader({
    id,
    label,
    children,
    linkClassName,
    tooltipClassName,
    place = 'top',
    type = 'dark',
    effect = 'float',
    clickable = true
}) {

    return (
        <>
            <Link
                className={cx('tooltip-anchor', {
                    [linkClassName]: !!linkClassName
                })}
                data-tooltip-id={id}
            >
                {label}
            </Link>
            <Tooltip
                className={cx('tooltip-content', {
                    [tooltipClassName]: !!tooltipClassName
                })}
                id={id}
                place={place}
                type={type}
                effect={effect}
                clickable={clickable}
            >
                {children}
            </Tooltip>
        </>
    );
}

export default FormSectionHeader;